import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

import PropTypes from "prop-types";

export default function ResourcesIndexPage({ data }) {
  return (
    <Layout>
      <SEO
        keywords={[`聖經介紹`, `聖經背景`, `聖經作者`, `聖經寫作目的`]}
        title="聖經介紹"
        description="這裡包括聖經不同書卷的介紹，讓你在宏觀的角度了解每部聖經書卷的背景、作者和寫作目的，加深你對聖經的了解。"
      />

      <section>
        <h1 className="text-center m-2 text-3xl font-bold m-3">聖經書卷簡介</h1>
        <div className="m-2">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
            {data.allMarkdownRemark.edges.map((edge, index) => (
              <h3
                key={index}
                className={`${edge.node.frontmatter.testamentType == "old" ? "bg-red-600" : "bg-blue-600"} text-white hover:bg-white hover:text-red-600 text-center p-2 m-1 text-xl`}
              >
                <Link to={`/resources/bibleBooks/${edge.node.frontmatter.englishName}/`}>
                  {edge.node.frontmatter.title}
                </Link>
              </h3>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
}

ResourcesIndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string,
              englishName: PropTypes.string,
              testamentType: PropTypes.string
            })
          })
        })
      )
    }),
  }),
};


export const query = graphql`
  query {
    allMarkdownRemark(sort: {fields: frontmatter___date, order: ASC}) {
      edges {
        node {
          frontmatter {
            title
            englishName
            testamentType
          }
        }
      }
    }
  }
`;
